<template>
  <downloadexcel
    :fetch="getChecklist"
    :fields="json_fields"
    name="checklist.xls"
    type="xls"
  >
    <el-button
      type="info"
      icon="el-icon-download"
    >Exportar</el-button>
  </downloadexcel>
</template>

<script>
import downloadexcel from 'vue-json-excel';
import { mapActions } from 'vuex';

export default {
  name: 'App',
  components: {
    downloadexcel,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    searchExport: {
      type: Function,
    },
  },
  data() {
    return {
      json_fields: {
        // Empresa: 'empresaName',
        Checklist: 'checklistName',
        Área: 'areaName',
        Pergunta: 'perguntaName',
        Resposta: 'respostaName',
        'Criado em': 'respostaCreate',
        'Respondido em': 'respostaDateEnd',
        Responsável: 'respostaResponsible',
        Comentário: 'comentario',
        Imagem: 'imagem',
        /* 'Finalizado em': 'respostaFinish', */
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    };
  },
  methods: {
    ...mapActions('checklist', ['checklistSerializer']),
    async getChecklist() {
      try {
        this.$swal({
          title: 'Preparando a Exportação',
        });
        this.$swal.showLoading();
        await this.searchExport();
        const res = await this.checklistSerializer(this.data);
        return res;
      } catch (error) {
        console.error(error);
      } finally {
        this.$swal.close();
      }
      return [];
    },
  },
};
</script>
